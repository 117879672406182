import React from 'react';
import CallUsBtn from '../components/callUsBtn';
import GutterlessList from "../components/testCmp";
import "../App.css";
import "./styles/home.css";
import { FaBloggerB } from "react-icons/fa";
import { FaArrowCircleUp } from "react-icons/fa";
import logo from '../assets/Icons/logo_nav_desktop.png';
import AnimationAddressBar from '../components/animationBar';
import EstimateCal from '../components/estimateCal';
import CarAndLight from '../components/carAndLight';
import MeetOurProfessionals from '../components/meetOurProfessionals';
import LogoSlider from '../components/LogoSlider';
import ServiceList from '../components/ourService/serviceList';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Home = () => {
  return (
    <div className='home-page-main-cmp'>
      <div className='home-page-first-main-cmp'>
      </div>
      <Container fluid>
       <Row>
        <Col xs={12} sm={12} md={5}>
        
              <p className='home-page-roadside-txt'>ROADSIDE <br></br><span className=''>RESCUE</span><br></br>STARTS HERE!</p>
              <CallUsBtn />
              {/* <CarAndLight /> */}
          
          </Col>
          <Col className='p-0' xs={12} sm={12} md={7}>
        
              <EstimateCal />
        
          </Col>
       </Row>

      </Container>
       {/* <LogoSlider /> */}
      {/* <ServiceList /> */}

      {/* <GutterlessList /> */}


    </div>
  );
};

export default Home;