import * as React from "react";
import "../App.css";
import { BsFacebook, BsInstagram, BsTwitterX, BsYoutube, BsSnapchat, BsTiktok, BsPinterest } from "react-icons/bs";
import { FaArrowCircleUp } from "react-icons/fa";
import logo from '../assets/Icons/logo_nav_desktop.png';
import AnimationAddressBar from '../components/animationBar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import EstimateCal from "./estimateCal";

const FooterCover = () => {
    return (
        <div className="footer-container">
            <div className="footer-cover-cmp">
                <Row className="footer-cover-row font-jost">
                    <Col className="footer-call-wrapper" md={6} xs={12}>
                        <div className="footer-call-txt">Call Us on <span style={{ fontWeight: '700' }}> (651) 917-0192 </span><FaArrowCircleUp className="header-call-icon" /></div>
                    </Col>
                    <Col className="footer-icon-container" md={6} xs={12}>
                        <div className="header-icon-wrapper">
                            <BsFacebook className="header-media-icon" />
                            <BsInstagram className="header-media-icon" />
                            <BsTwitterX className="header-media-icon" />
                            <BsYoutube className="header-media-icon" />
                            <BsSnapchat className="header-media-icon" />
                            <BsTiktok className="header-media-icon" />
                            <BsPinterest className="header-media-icon" />
                        </div>
                    </Col>

                </Row>
            </div>
            <div className="footer-content-wrapper">
                <Row>
                    <Col md={4} xs={12}>
                        <Row>
                            <Col style={{ display: 'flex', flexDirection: 'column', gap: '20px' }} md={8} xs={12}>
                                <div className="d-flex flex-row">
                                    <img src={logo} className="footer-cover-logo" />
                                    <h5 className="d-flex align-items-center m-0 ps-3 font-color-white">Minneapolis Towing Service</h5>
                                </div>
                                <p style={{ marginBottom: '56px' }}>Wherever you might be in Minnesota, Discount Auto Towing is always just a call away.
                                    Get in touch today and experience the best in towing services tailored to your needs.</p>

                                <h5>Our Location</h5>
                                <div className="footer-location-card">
                                    <p>Minnesota towing services1047 Raymond AvenueSt. Paul MN 55108</p>
                                </div>

                            </Col>
                            <Col style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingLeft: '38px' }} xs={12} md={4}>
                                <h5>Site map</h5>
                                <ul className="d-flex flex-column footer-content-list ps-0">
                                    <li>Home</li>
                                    <li>Services</li>
                                    <li>Service Areas</li>
                                    <li>About Us</li>
                                    <li>Contact Us</li>
                                    <li>Estimation Tool</li>
                                    <li>Privacy Policy</li>
                                </ul>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}  xs={12}>
                        <EstimateCal isDark />
                    </Col>
                    <Col md={4}  xs={12}>
                        <Row>
                            <Col md={6} xs={12} style={{ display: 'flex', flexDirection: 'column', gap: '20px', paddingLeft: '24px' }}>
                                <h5>Services</h5>
                                <ul className="d-flex flex-column footer-content-list ps-0">
                                    <li>24/7 Emergency Towing</li>
                                    <li>Accident Towing</li>
                                    <li>Winching & Recovery Service</li>
                                    <li>Car & Light Truck Towing</li>
                                    <li>Flatbed Towing</li>
                                    <li>Show Car Transporting</li>
                                    <li>Fuel Delivery Service</li>
                                    <li>Jumpstart Service</li>
                                    <li>Lockout Service</li>
                                    <li>Tire Change & Repair</li>
                                    <li>Cash For Junk Cars Service</li>
                                    <li>Junk Car Removal</li>
                                </ul>
                            </Col>
                            <Col md={6} xs={12}>
                                <h5>Get in touch</h5>
                                <p>Minnesota towing services,1047 Raymond Avenue,St. Paul, MN 55108</p>
                                <p>Minnesota towing services,526 Continental dr,New Brighton, MN 55112</p>
                                <h3>(651) 917 - 0192</h3>
                                <p>shawnstowing@gmail.com</p>
                                <p>24 hours a day, 7 days a week</p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div style={{ color: '#333333', display: 'flex', justifyContent: 'center', padding: '20px' }}>
                < p className="m-0 text-center">Copyright © 2024 Minneapolis Towing Service All rights reserved.​</p>
            </div>

        </div>
    );
}

export default FooterCover;