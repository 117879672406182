import React from 'react';
import "../../App.css";
import "../styles/components.css";
import image1 from "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { render } from '@testing-library/react';

const ServiceList = () => {
    const items = [
        { my: 1, title: "test_1", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
        { my: 2, title: "test_2", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
        { my: 3, title: "test_3", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
        { my: 4, title: "test_4", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
        { my: 5, title: "test_5", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
        { my: 6, title: "test_6", content_title: "test2", content: "content", image_bg: "../../assets/img/car-on-tow-truck-on-snow-road-2023-11-27-05-18-10-utc.jpg" },
    ];
    return (
        <>
            <div className='service-list-main-cmp'>
                <img src={image1} width="700" height="500" className='service-list-main-backgroud' ></img>
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>


                    {items.map((item, index) => (
                        <div key={index}>
                            <p>{item.title}</p>
                        </div>

                    ))}


                </List>

            </div>

            {/* {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))} */}
        </>
    );
};

export default ServiceList;