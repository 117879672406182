import React, { useEffect, useState } from "react";
import "../App.css";
import { BsFacebook, BsInstagram, BsTwitterX, BsYoutube, BsSnapchat, BsTiktok, BsPinterest, BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowCircleUp } from "react-icons/fa";
import logo from '../assets/Icons/logo_nav_desktop.png';
import AnimationAddressBar from '../components/animationBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Drawer from "./drawer";

const HeaderCover = () => {
    const [isHeaderSticky, setIsHeaderSticky] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 10 && window.innerWidth <= 480) {
                setIsHeaderSticky(true);
            } else {
                setIsHeaderSticky(false);
            }
        };

        const handleResize = () => {
            if (window.innerWidth <= 480) {
                setIsSmallScreen(true);
            } else {
                setIsSmallScreen(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return isHeaderSticky ? (
        <>
            <div className="sticky-header d-flex align-items-center">
                <Col className="p-4" xs={3}>
                    <img src={logo} className="header-cover-logo" alt="Minneapolis Towing Service Logo" />
                </Col>
                <Col xs={7}>
                    <div className="header-cover-contact justify-content-start">
                        <button style={{ fontSize: '13px' }} className="header-call-btn">Call Us on <span style={{ fontWeight: '700' }}> (651) 917-0192 </span><FaArrowCircleUp className="header-call-icon" /></button>
                    </div>
                </Col>
                <Col className="d-flex justify-content-center" xs={2}>
                    <button onClick={toggleDrawer} className="header-more-btn"><BsThreeDotsVertical /></button>
                </Col>
            </div>
            <Drawer  isOpen={isDrawerOpen} onClose={toggleDrawer} />
        </>
    ) : isSmallScreen ? (
        <>
            <div className="header-cover-cmp">
                <div className="header-cover-animation-address-bar">
                    <AnimationAddressBar />
                </div>
                <div className="d-flex justify-content-end">
                    <button onClick={toggleDrawer} className="header-more-btn"><BsThreeDotsVertical /></button>
                </div>
                <div className="header-cover-logo-cmp">
                    <img src={logo} className="header-cover-logo" />
                </div>

                <div style={{ flexDirection: 'column' }} className="header-cover-contact">
                    <button className="header-call-btn">Call Us on <span style={{ fontWeight: '700' }}> (651) 917-0192 </span><FaArrowCircleUp className="header-call-icon" /></button>
                    <a href="mailto:shawnstowing@gmail.com" >shawnstowing@gmail.com</a>
                </div>
                <div className="header-icon-wrapper">
                    <BsFacebook className="header-media-icon" />
                    <BsInstagram className="header-media-icon" />
                    <BsTwitterX className="header-media-icon" />
                    <BsYoutube className="header-media-icon" />
                    <BsSnapchat className="header-media-icon" />
                    <BsTiktok className="header-media-icon" />
                    <BsPinterest className="header-media-icon" />
                </div>

            </div>
            <Drawer isOpen={isDrawerOpen} onClose={toggleDrawer} />
        </>
    ) : (
        <div className="header-cover-cmp">
            <div>
                <Row>
                    <Col className="align-content-center ps-5" xs={6}>
                        <div className="header-icon-wrapper">
                            <BsFacebook className="header-media-icon" />
                            <BsInstagram className="header-media-icon" />
                            <BsTwitterX className="header-media-icon" />
                            <BsYoutube className="header-media-icon" />
                            <BsSnapchat className="header-media-icon" />
                            <BsTiktok className="header-media-icon" />
                            <BsPinterest className="header-media-icon" />
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="header-cover-contact">
                            <a href="mailto:shawnstowing@gmail.com" >shawnstowing@gmail.com</a>
                            <button className="header-call-btn">Call Us on <span style={{ fontWeight: '700' }}> (651) 917-0192 </span><FaArrowCircleUp className="header-call-icon" /></button>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="header-cover-logo-cmp">
                <img src={logo} className="header-cover-logo" />
            </div>


            <div className="mb-3">
                <Row>
                    <Col xs={4}>
                        <div className="header-cover-estimate">
                            <h2 className="header-estimate-text">ESTIMATE MY TOW</h2>
                            <FaArrowCircleUp className="header-call-icon" />
                        </div>
                    </Col>
                    <Col xs={5}>
                        <div className="header-cover-animation-address-bar">
                            <AnimationAddressBar />
                        </div>
                    </Col>
                    <Col className="header-year-txt" xs={3}>
                        <p className="font-jost">Since 1995</p>
                    </Col>
                </Row>
            </div>

        </div>
    );
}

export default HeaderCover;
