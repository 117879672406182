import React, { useEffect, useState } from 'react';
import '../components/styles/estimateCal.css';
import '../App.css';
import { BsSearch, BsCrosshair, BsArrowUpRight } from "react-icons/bs";
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fetchData, postData } from '../api/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    deliverLocation: yup.string().email('Invalid email').required('Delivery location is required'),
    currentLocation: yup.string().required('Current location is required'),
    contactNumber: yup.number().required('Contact number is required'),
    vehicleType: yup.string().required('Vehicle type is required')
});

const EstimateCal = ({ isDark = false }) => {
    const { register, handleSubmit, formState: { errors }, reset, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
    });


    const onSubmit = async (event) => {
        console.log("sss");
        const form = event.currentTarget;
        // const response = await fetch('https://roofprocal.10xtek.com/vehicle-types');
        // const data = await response.json();
        // console.log(data);
        // const body = 
        //     {
        //         "name": "John Doe",
        //         "phone": "123-456-7890",
        //         "current_location": "123 Main St",
        //         "delivery_location": "456 Elm St",
        //         "vehicle_type_id": 2
        //     }

        // const res = postData('https://roofprocal.10xtek.com/add-tow-request', body);
        // if (res) console.log(res.data);
        // setValidated(true);
    };



    useEffect(() => {
        const fetchVehicleList = async () => {
            try {
                const response = await fetch('https://roofprocal.10xtek.com/vehicle-types');
                const result = await response.json();
                console.log(result);
                // setVehicleTypes(data);
              } catch (error) {
                console.error('Error fetching vehicle types:', error);
              }
        }

        fetchVehicleList();
    }, [])

    return (
        <Container className={isDark ? "estimate-cal-main-dark" : "estimate-cal-main-cmp"}>
            <Row>
                <Col xs={12} md={isDark ? 12 : 7}>
                    {!isDark && (<p className='mb-0 estimate-no-hidden-txt'>
                        No hidden charges!
                    </p>)}
                    <p className={isDark ? "estimate-cal-header-tex-dark" : 'estimate-cal-header-tex'} >
                        ESTIMATE MY <span style={{ color: '#FF5C00' }} >TOW</span>
                    </p>

                    <Form style={{ marginTop: "6px" }} onSubmit={handleSubmit(onSubmit)}>
                        <Row className='justify-content-center'>
                            <Col xs={12} md={6} className='estimate-cal-form'>
                                <Form.Group controlId="estimate-cal-input1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        className='mb-3 estimate-cal-form-input'
                                        type="text"
                                        placeholder="Name"
                                        {...register('name')}
                                        isInvalid={errors.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="estimate-cal-input2">
                                    <Form.Label>Where you want to deliver your vehicle?</Form.Label>
                                    <InputGroup className="mb-3 estimate-cal-form-input estimate-cal-input-group">

                                        <Form.Control
                                            className='clip-path-content placeholder-txt'
                                            placeholder="-Search-"
                                            {...register('deliverLocation')}
                                            isInvalid={errors.deliverLocation}
                                        />
                                        <InputGroup.Text className='clip-path-content estimate-input-icon' id="basic-addon1"><BsSearch style={{ color: '#595959' }} /></InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.deliverLocation?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="estimate-cal-input3">
                                    <Form.Label>Where are you at?</Form.Label>
                                    <InputGroup className="mb-3 estimate-cal-form-input estimate-cal-input-group">
                                        <Form.Control
                                            className='clip-path-content placeholder-txt'
                                            placeholder="Find my location"
                                            {...register('currentLocation')}
                                            isInvalid={errors.currentLocation}
                                        />
                                        <InputGroup.Text className='clip-path-content estimate-input-icon' id="basic-addon1"><BsCrosshair style={{ color: '#595959' }} /></InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.currentLocation?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6} className='estimate-cal-form'>
                                <Form.Group controlId="estimate-cal-input4">
                                    <Form.Label>Contact No.</Form.Label>
                                    <Form.Control
                                        className='mb-3 estimate-cal-form-input'
                                        type="text"
                                        placeholder="Contact No."
                                        {...register('contactNumber')}
                                        isInvalid={errors.contactNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.contactNumber?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="estimate-cal-input5">
                                    <Form.Label>Vehicle Type</Form.Label>
                                    <Form.Select className='mb-3 estimate-cal-form-input'  {...register('vehicleType')}
                                        isInvalid={errors.vehicleType}>
                                        <option className='placeholder-txt'>-Select-</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.vehicleType?.message}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Button type="submit" className='btn-primary'>Estimate</Button>
                    </Form>



                </Col>
                <Col className='estimate-info-section' xs={12} md={isDark ? 12 : 5} >
                    {!isDark && (<div className='estimate-info-section-1 text-end'>
                        <p>
                            Input your vehicle type, current location, and destination to estimate towing costs instantly
                        </p>
                        <Link className='estimate-cal-link'>More about towing calculator <BsArrowUpRight className='estimate-arrow-icon' /></Link>
                    </div>)}
                    <div className={isDark ? "estimate-cal-term-box-dark estimate-cal-term-box" : 'estimate-cal-term-box'}>
                        <p variant="body1" color="textPrimary">
                            Your Tow Would be
                        </p>
                        <div>
                            <p className={`mb-0 estimate-fee-txt ${isDark ? "font-color-white" : ""}`} >
                                <span style={{ color: '#FF5C00' }}>$</span>120.00
                            </p>
                            <p className='text-end'>approx.</p>
                        </div>

                        <p className={`mb-0 estimate-cal-terms-tex ${isDark ? "font-color-white" : ""}`}>*Terms and Conditions applied</p>
                    </div>




                </Col>
            </Row>
        </Container>
    );
};

export default EstimateCal;
