import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import logo from '../assets/Icons/logo_nav_desktop.png';
import "./styles/drawer.css";
import AppNavBar from './navBar';
import { FaArrowCircleUp } from "react-icons/fa";

const Drawer = ({ isOpen, onClose }) => {
    return (
        <Offcanvas show={isOpen} onHide={onClose}>
        <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='ps-4'>
        <div className="d-flex flex-row drawer-header">
                    <img src={logo} className="drawer-logo" />
                    <h5 className="d-flex align-items-center m-0 ps-3">Minneapolis Towing Service</h5>
                </div>
                <div className='d-flex flex-column ps-3 mt-2 mb-5'>
                    <AppNavBar isDesktop={false} toggleDrawer={onClose}/>
                </div>
                <div>
                    <p className='ps-3'>shawnstowing@gmail.com</p>
                    <p className='ps-3'>24 hours a day, 7 days a week</p>
                    <button className="drawer-call-btn">Call Us on <span style={{ fontWeight: '700' }}> (651) 917-0192 </span><FaArrowCircleUp className="header-call-icon" /></button>
                </div>
        </Offcanvas.Body>
      </Offcanvas>
    );
};

export default Drawer;