import * as React from 'react';
import { Outlet, NavLink } from "react-router-dom";
import { Nav } from 'react-bootstrap';

const AppNavBar =({isDesktop, toggleDrawer = null}) =>{
    return (
        <Nav className={`${isDesktop ? 'app-main-navbar-cmp' : 'app-main-navbar-mobile flex-column'}`} variant="underline" defaultActiveKey="/">
            <Nav.Item>
            <NavLink to="/" className='nav-link' onClick={toggleDrawer}>Home</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/services" className='nav-link' onClick={toggleDrawer}>Services</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/serviceAreas" className='nav-link' onClick={toggleDrawer}>Service Areas</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/aboutUs" className='nav-link' onClick={toggleDrawer}>About Us</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/contactUs" className='nav-link' onClick={toggleDrawer}>Contact Us</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/blogs" className='nav-link' onClick={toggleDrawer}>Blogs</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/faqs" className='nav-link' onClick={toggleDrawer}> FAQs</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/estimateTow" className='nav-link' onClick={toggleDrawer}>Estimate Tow</NavLink>
            </Nav.Item>
            <Nav.Item>
                <NavLink to="/privacyPolicy" className='nav-link' onClick={toggleDrawer}>Privacy Policy</NavLink>
            </Nav.Item>
        </Nav>
    );
}
export default AppNavBar;